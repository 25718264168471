import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { createSlug } from '../../util/urlHelpers';
import { NamedLink, IconArrowHead } from '../../components';
import classNames from 'classnames';

import css from './HostsPage.module.css';

const SectionHero = props => {
  const { title, richTitle, listingId, isListingOfShowType } = props;

  // title container
  const titleContainer = (
    <div className={css.titleContainer}>
      <h1 className={css.title}>{richTitle}</h1>
    </div>
  );

  const returnLink = (
    <NamedLink
      className={css.returnLink}
      name={isListingOfShowType ? 'ListingPage' : 'ExperiencePage'}
      params={{ id: listingId.uuid, slug: createSlug(title) }}
    >
      <IconArrowHead className={css.returnLinkIcon} direction="left" />
      <FormattedMessage id="HostsPage.returnText" />
    </NamedLink>
  );

  // top content
  const topContent = <div className={css.topContent}>{returnLink}</div>;

  const classes = classNames(css.sectionHero, {
    [css.sectionHeroExperience]: !isListingOfShowType,
  });

  return (
    <div className={classes}>
      <div className={css.heroContent}>
        {topContent}
        {titleContainer}
      </div>
    </div>
  );
};

export default SectionHero;
